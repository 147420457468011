import axios from 'axios';  
import Vue from 'vue';

export const baseInstanceAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

const isAxiosHandlerEnabled = (config = {}) => {
	 
	return Object.prototype.hasOwnProperty.call("handlerEnabled") && !config.handlerEnabled
        ? false
        : true;
};

const requestAxiosHandler = request => {
    if (isAxiosHandlerEnabled(request)) {
        request.headers['Content-Type'] = 'application/json';  
		
    }
    return request;
};

const successAxiosHandler = response => {
    if (isAxiosHandlerEnabled(response.config)) {
        // Handle responses
    }
    return response;
};

const errorAxiosHandler = error => {
    if (isAxiosHandlerEnabled(error.config)) {
        console.log('errorAxiosHandler');
        if (error.response.status === 401) {
             console.log('error 401');
        } else if (
            error.response.status == 403 &&
            error.response.status == 404
        ) {
             console.log('error 403/404');
        } else if (error.response.status === 500) {
            Vue.prototype.$snotify.error(
                'Lo sentimos,hubo un error en el servidor'
            );
        }
    }
    return Promise.reject({ ...error });
};

// Add interceptors
baseInstanceAxios.interceptors.request.use(request =>
    requestAxiosHandler(request)
);

baseInstanceAxios.interceptors.response.use(
    response => successAxiosHandler(response),
    error => errorAxiosHandler(error)
);
