<template>
<div>
    <Header/>
    <div>
    <section class="section-info container">
        <div class="info">
            <img src="@/assets/img/headerIcono1.png" alt="" class="imagen1">
            <h6>Inspectores con <br class="br1"> Acreditación Internacional</h6>
        </div>
         <div  class="info info-medium">
            <img src="@/assets/img/headerIcono2.png" alt="">
            <h6>Registrados <br >  en el INDECOPI</h6>
        </div>
        <div class="info info3">
            <img src="@/assets/img/headerIcono3.png" alt="" class="imagen3">
           <h6>Profesionales <br class="br1">  Miembros <br> del ASME</h6>
        </div>
    </section>
    <div class="parallax" id="machinery">
    <section class="bg-parallax">
        <b-row class="row-bg">
        <b-col  cols="12" xl="6" class="container ">
            <div class="text">
                <h6>Inspección <br class="br2"> de Maquinaria <br> y Accesorios</h6>
                <p>Certifica tus equipos y cumple <br class="br2"> con las exigencias del mercado.</p>
            </div>
        </b-col>
        </b-row>
    </section>
         <img src="@/assets/img/inspección4.jpg" alt="">
    </div>
    <div class="container">
        <b-row>
        <section class="services-section">
            <h6 class="title title-inspection">Servicios <br> de inspección</h6>
            <div class="container-services">
            <b-col cols="12" lg="6" class="contents-services">
                <div class="service">
                    <img src="@/assets/img/icono1.png" alt="">
                    <h6>Grúas</h6>
                    <ul>
                        <li>Grúas móviles</li>
                        <li>Grúas articuladas</li>
                        <li>Grúas torre</li>
                        <li>Grúas puente</li>
                    </ul>
                </div>
                
                <div class="service">
                <img src="@/assets/img/icono2.png" alt="">
                <h6>Montacargas y <br> Manipuladores</h6>
                <ul>
                    <li>Montacargas</li>
                    <li>Manipuladores telescópicos</li>
                </ul>
                </div>
            </b-col>
            <b-col cols="12" lg="6" class="contents-services">
                <div class="service">
                <img src="@/assets/img/icono3.png" alt="">
                <h6>Maquinaria de <br> contrucción</h6>
                <ul>
                    <li>Excavadoras</li>
                    <li>Perforadoras</li>
                    <li>Bombas de concreto, etc.</li>
                </ul>
                </div>
                <div class="service">
                <img src="@/assets/img/icono4.png" alt="">
                <h6>Elementos <br> de Izaje</h6>
                <ul>
                    <li>Eslingas</li>
                    <li>Cables de acero</li>
                    <li>Gachos, grilletes, etc.</li>
                </ul>
                </div>
            </b-col>
            <b-col cols="12" lg="6" class="contents-services last">
                <div class="service">
                <img src="@/assets/img/icono5.png" alt="">       
                <h6>Elevadores de <br> Personal Manlift</h6> 
                <ul>
                    <li>Plataformas de tijera</li>
                    <li>Manlift articulados</li>
                    <li>Manlift telescópicos</li>
                </ul>
                </div>
            </b-col>
            </div>
        </section>
    </b-row>
    </div>
    <div class="parallax parallax2" id="personal">
        <section class="bg-parallax bg-2">
            <b-row class="personal-row">
                <b-col cols="12" xl="6" class="personal container" >
                    <div class="text personal-text">
                    <h6>Certificación <br> de personal</h6>
                    <p>Asegúrate que tus trabajadores cumplan con <br class="br2"> la experiencia <br> requerida por la normativa.</p>
                </div>
                </b-col>
            </b-row>
        </section>
         <img src="@/assets/img/certificado6.jpg" alt="">
    </div>
    <div class="container">
        <section class="services-section">
            <h6 class="title title2">Evaluación <br> de Operadores y Riggers</h6>
            <b-row>
                <b-col class="check" cols="12" xl="12">
                    <p><img src="@/assets/img/check.png" alt="">Evaluaciones en línea </p>
                    <p><img src="@/assets/img/check.png" alt="">Cursos de capacitación</p>
                </b-col>
                <b-col cols="12" xl="6" class="service-cols">
                <div class="service">
                    <div class="img-doble">
                    <img class="operador" src="@/assets/img/IconoDoble1.png" alt="">
                    <img  class="grua" src="@/assets/img/grua-small.png" alt="">
                    </div>
                    <h6 class="title-service">Operador</h6>
                    <ul>
                        <li>Grúas</li>
                        <li>Montacargas</li>
                        <li>Elevadores</li>
                        <li>Maquinaria</li>
                    </ul>
                </div>
                <div class="service service2">
                    <div class="img-doble">
                    <img class="operador" src="@/assets/img/IconoDoble3.png" alt="">
                    <img class="operador-grua" src="@/assets/img/grua-small.png" alt="">
                    </div>
                <h6 class="title-service">Rigger de grúa</h6>
                <ul>
                    <li>Rigger de grúa móvil</li>
                    <li>Rigger de grúa torre</li>
                </ul>
                </div>
                </b-col>
            </b-row>
        </section>
    </div>
    <Videos/>
    </div>
</div>
</template>
<script>

import Header from '@/components/header.vue'
import Videos from '@/components/videos.vue'
export default {
    components:{
        Header,
        Videos
    }
}
</script>