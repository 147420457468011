<script>
import Nav from '@/components/global/Navbar';
import Footer from "@/components/global/Footer";
import msg from "@/components/msgContacto";

export default {
  components: {
    Nav,
    msg,
    Footer
  }
};
</script>

<template>
  <div class="wrapper">
    <Nav />
    <msg/>
    <slot />
    <Footer />
  </div>
</template>