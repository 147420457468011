<template>
        <div>
          <div class="header" id="header">
                <img src="@/assets/img/headerBanner.jpg" alt="">
            <div class="header-title">
                <b-container>
                    <section>
                    <div class="text">
                        <h6>Inspección <span>de grúas y</span> certificación <span>de operadores</span></h6>
                    <!-- <h6>Inspección</h6>
                    <span>de grúas y</span>
                    <h6>certificación</h6>
                    <span>de operadores</span> -->
                    </div>

                    <div class="certificate">
                        <h6 class="check">VERIFICA  <br> LOS CERTIFICADOS AQUÍ</h6>
                        <p>INGRESA EL CÓDIGO DE CERTIFICADO</p>
                         <div class="form-group">
                        <input type="text" class="number" v-model="numberCertificado" required @blur="validateMensajeKey" id="txtMensaje" >
                          <p v-if="valMensaje" class="error-label" style="color: #ffa5ad!important;">Debe ingresar el código de certificado.</p>
                          </div>
                        <button @click="routerCertificado">VERIFICAR</button>
						<p class="msg">Para certificados emitidos después de Noviembre del 2021.</p>
						
                    </div>
                    </section>
                </b-container>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            numberCertificado:'',
              valMensaje: false,
        }
    },
    methods:{
      validateMensajeKey: function () {
      this.valMensaje = false;
      this.checkForm();
      },
     checkForm: function () {
      let error = 0;
      if (this.numberCertificado == "") {
        this.valMensaje = true;
        error = error + 1;
      }

      return error == 0;
      },
      routerCertificado(){
      let valid = this.checkForm();
       if (valid) {
        this.$router.push('/certificado/' + this.numberCertificado);
            }
        },
    resetInput: function () {
     this.valMensaje = false;
    },
    },

}
</script>