<template>
    <div class="container-fluid certificado">
        <div class="registrado container">
            <h2>Consultar certificado digital</h2>
            <h6>Profesional Registrado:</h6>
            <h2 class="text-center mb-4" v-if="isLoading">Cargando</h2>
            <div v-if="!isLoading">
                <label v-if="Object.keys(certificado).length === 0" class="label-error">No existe un certificado asociado a los datos de consulta. Verifique que ha ingresado los datos correctos.</label>
                <div v-else class="datos" >
                    <ul>
                        <li><p>Nombre y Apellidos: </p><strong>{{certificado.nombreCompleto}}</strong></li>
                        <li><p>Documento de identidad: </p><strong>{{certificado.nroDocumento}}</strong></li>
                        <li><p>Certificación: </p><strong>{{certificado.certificacion}}</strong></li>
                        <li><p>Código de certificado: </p><strong>{{certificado.nroCertificado}}</strong></li>
                        <li><p>Vigencia desde: </p><strong>{{certificado.vigenciaDesde}}</strong></li>
                        <li><p>Vigencia Hasta:</p><strong>{{certificado.vigenciaHasta}}</strong></li>
                        <li><p>Estado: </p><strong :style="certificado.flagVencido ? 'color:red' : 'color:green'">{{certificado.estadoVigencia}}</strong></li>
                        <li><p>Normas de Referencia: </p><strong>{{certificado.normasClases}}</strong></li>
                        <li><p>Empresa / Titular: </p><strong>{{certificado.empresa}}</strong></li>
                    </ul> 
                </div>
            </div>
            <b-button @click="routerHome">Volver a consultar</b-button>
        </div>
    </div>
</template>
<script>
import { baseInstanceAxios } from "@/assets/js/axiosBase.js";

export default {
    data(){
        return{
            API_URL: process.env.VUE_APP_API_URL,
            certificado:{},
            isLoading: false
        }
    },
    mounted(){
        this.obtenerCertificado();
    },
    methods:{
        obtenerCertificado(){
            this.isLoading = true;
            baseInstanceAxios({
                method: "POST",
                url: this.API_URL + "/ObtenerCertificado",
                data: {
					dto: {Codigo: this.$route.params.id}
				}
            })
            .then(response => {
                if(response.status == 200 && response.data.dto != null){
                    this.certificado = {
                        nombreCompleto: response.data.dto.certificado.nombreCompleto,
                        nroDocumento: response.data.dto.certificado.nroDocumento,
                        certificacion: response.data.dto.certificado.certificacion,
                        nroCertificado: response.data.dto.certificado.nroCertificado,
                        calificacion: response.data.dto.certificado.calificacion,
                        vigenciaDesde: response.data.dto.certificado.vigenciaDesde,
                        vigenciaHasta: response.data.dto.certificado.vigenciaHasta,
                        empresa: response.data.dto.certificado.empresa,
                        flagVencido: response.data.dto.certificado.flagVencido,
                        estadoVigencia: response.data.dto.certificado.estadoVigencia,
                        normasClases: response.data.dto.certificado.normasClases ? response.data.dto.certificado.normasClases.join(',') : "",
                    }
                }
            })
            .catch(function(error){
                console.log(error);
            })
            .finally(() => {
                this.isLoading = false;
            })
        },
        routerHome(){
            this.$router.push('/');
            window.scrollTo(0,0);
        }
    },
}
</script>