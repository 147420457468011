<template>
     <section class="training" id="training">
      <!-- <img class="img-fondo" src="@/assets/img/fondoVideos.png" alt=""> -->
        <b-container>
          <section>
            <h6 class="title">Videos de Capacitación</h6>
            <b-row class="training-row">
              <b-col cols="12" lg="4" v-for="(item, index) in listaVideo" v-bind:key="index">
                  <div class="card_video"  v-show="item.flagVisible">
                    <iframe
                    :src="item.url+'&showinfo=0&modestbranding=1'"
                    width="100%" 
                    frameborder="0" 
                    allowfullscreen>
                    </iframe>
                    <!-- <b-embed 
                    type="iframe"
                    :src="item.url"
                    allowfullscreen
                    class="w-100"
                    
                    ></b-embed> -->
                    
                    <h6>{{item.titulo}}</h6>
                    <p>Publicado: {{item.fechaPublicacion}}</p>
                  </div>
              </b-col>
            </b-row>
          </section>
        <div class="visile-btn">
          <button v-if="btnShow" class="btn btn_show" v-on:click="mostrarVideo(btnShow)">Ver más 
            <span>+</span>
          </button>
          <button v-else class="btn btn_show" v-on:click="mostrarVideo(btnShow)">Ocultar 
            <span class="subtract">-</span>
          </button>
        </div>
        </b-container>
     </section>
</template>
<script>
import { baseInstanceAxios } from "@/assets/js/axiosBase.js";

export default {
  data(){
    return{
      btnShow:true,
      API_URL: process.env.VUE_APP_API_URL,
      listaVideo: [],
      isResponsive: false,
    }
  },
  mounted(){

    if (window.innerWidth < 1100) {
      this.isResponsive = true;
    } else {
      this.isResponsive === false;
    }
    this.listarVideo();
  },
  methods:{
    listarVideo(){

      baseInstanceAxios({
        method: "GET",
        url: this.API_URL + "/ListarVideo"
      })
        .then(response => {
          if(response.status == 200){
            let self = this;
            response.data.dto.videos.forEach(function(e, i){
              let urlEmbed = "", code="";
              let flagUrl = false;
              if(e.url_Video.includes("vimeo")){
                 
                code = e.url_Video.replace("https://vimeo.com/", "").replace("/","");
                urlEmbed = `https://player.vimeo.com/video/${code}?&portrait=0&controls=1&buttons.share=false&embed.buttons.like=false`;
                flagUrl =true;
              }
              else if (e.url_Video.includes("youtu")){
                
                if(e.url_Video.includes("youtube.com/watch"))
                 {
                 
                code = e.url_Video.replace("https://www.youtube.com/watch?v=","");
               let newCode = code.substring(0,11);
                urlEmbed = `https://www.youtube.com/embed/${newCode}?rel=0`;
                 flagUrl =true;
                 }
              else if(e.url_Video.includes("youtu.be"))
                 {
                 
                code = e.url_Video.replace("https://youtu.be/","");
                urlEmbed = `https://www.youtube.com/embed/${code}?rel=0`;
                 flagUrl =true;
                 }
              }
              
            if (flagUrl)
            {
            self.listaVideo.push({
                fechaPublicacion: e.fechaPublicacion,
                titulo: e.titulo,
                url: urlEmbed,
                flagVisible: self.isResponsive ? (i < 2 ? true : false) : true
               });
            }
            });

          }
          if(this.listaVideo.length < 3) this.$el.querySelector(".visile-btn").style.display = "none";
        })
        .catch(function(error){
          console.log(error);
        })
    },
    mostrarVideo(state){
      if(state)
        this.listaVideo.find(function(e){ e.flagVisible = true});
      else
        this.listaVideo.find(function(e,i){ if(i > 2) e.flagVisible = false});

      this.btnShow = !state;
    }
  }
}
</script>